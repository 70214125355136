import style from './EmptryPage.module.scss';

// React
import React from 'react';


type Props = {
    title: string;
    text: string;
}

const EmptyPage: React.FC<Props> = ({title, text}: Props) => {
    return (
        <div>
            <div className={style.title}>
                <h1>{title}</h1>
            </div>
            <div className={style.not_exist_sub_title}>
                <p>{text}</p>
            </div>
        </div>
    )
}

export default EmptyPage;