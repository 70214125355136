import $api from '../http/index';

import { API_TELEGRAM_WEBHOOK } from '../http/index';
import { getInviteReferralFormData } from '../redux/invite-code/types';


export default class InviteService {
    static async getInvite() {
        const res = await $api.get('telegram-web-app/v1/get_invite_code')
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            return err.response.data;
        })
        return res;
    }
    static async getInviteReferral(formData: getInviteReferralFormData) {
        const res = await $api.post(`${API_TELEGRAM_WEBHOOK}/getting_invite_code`, formData)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            return err.response.data;
        })
        return res;
    }
}
