import style from './Question.module.scss';

// Ant Design
import { MinusCircleFilled, PlusCircleFilled } from '@ant-design/icons';


type Props = {
    title: string;
    description: string;
    value: boolean;
    action: React.Dispatch<React.SetStateAction<boolean>>;
}

const Question = ({ title, description, value, action }: Props) => {
    return (
        <div className={style.questions__question}>
            <div className={style.question__main}>
                <p>{title}</p>
                {value ? <MinusCircleFilled onClick={() => action(!value)} /> : <PlusCircleFilled onClick={() => action(!value)} />}
            </div>
            {value && (
                <div className={style.question__description}>
                    <p dangerouslySetInnerHTML={{__html: description}}></p>
                </div>
            )}
        </div>
    )
}

export default Question;