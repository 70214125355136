import { createAsyncThunk } from '@reduxjs/toolkit';

import { ActiveServiceFormData } from './types';

import PackagesService from '../../services/PackagesService';


export const requestActiveService = createAsyncThunk('/telegram-web-app/v1/user/request', async (formData: ActiveServiceFormData) => {
    const response = await PackagesService.activeService(formData);
    return response;
})
