// @ts-nocheck

import axios, {AxiosInstance} from 'axios';
import { AuthFormData } from '../redux/auth/types';


const webApp = window?.Telegram?.WebApp;

export const API_URL: string = `${process.env.REACT_APP_BACKEND_API_URL}`;
export const API_TELEGRAM_WEBHOOK: string = `${process.env.REACT_APP_BACKEND_TELEGRAM_WEBHOOK_URL}`;

const $api: AxiosInstance = axios.create({
    baseURL: API_URL,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Token ${localStorage.getItem('token')}`
    }
});

$api.interceptors.response.use(
  response => {
    return response;
  },
  async error => {
    const originalRequest = error.config;
  
    if (error.response.status === 401 && !originalRequest._retry) {
      
        originalRequest._retry = true; // Пометка, что запрос уже пытался повториться
        try {
          // Запрос на получение нового токена
          const formData: AuthFormData = {
            'telegram_id': webApp.initDataUnsafe.user.id ? `${webApp.initDataUnsafe.user.id}` : null,
            'first_name': webApp.initDataUnsafe.user.first_name ? webApp.initDataUnsafe.user.first_name : null,
            'last_name': webApp.initDataUnsafe.user.last_name ? webApp.initDataUnsafe.user.last_name : null,
            'username': webApp.initDataUnsafe.user.username ? webApp.initDataUnsafe.user.username : null,
          }
          
          const response = await axios.post(`${API_URL}telegram-web-app/v1/auth/telegram`, formData);
          const { token } = response.data;

          localStorage.setItem('token', token); // Обновление токена в хранилище
          $api.defaults.headers.common['Authorization'] = `token ${token}`; // Обновление токена в заголовках
          return $api(originalRequest); // Повторный запрос с обновленным токеном
        } catch (refreshError) {
          // Обработка ошибок при получении нового токена
          return Promise.reject(refreshError);
        }
    }
    return error;
  }
)


export default $api;