import axios from 'axios';

import { API_TELEGRAM_WEBHOOK } from '../http/index';
import { CreateUrlPaymentFormData } from '../redux/payments/types';


export default class PaymentsService {
    static async createUrlPayment(formData: CreateUrlPaymentFormData) {
        const res = await axios.post(`${API_TELEGRAM_WEBHOOK}/getting_invoice_link`, formData)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            return err.response.data;
        })
        return res;
    }
}