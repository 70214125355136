import { createAsyncThunk } from '@reduxjs/toolkit';

import PackagesService from '../../services/PackagesService';


export const requestMyActivePackages = createAsyncThunk('/client/v1/user/package/active', async () => {
    const response = await PackagesService.getMyActivePackages();
    return response;
})

export const requestMyInactivePackages = createAsyncThunk('/client/v1/user/package/inactive', async () => {
    const response = await PackagesService.getMyInactivePackages();
    return response;
})
