import { createAsyncThunk } from '@reduxjs/toolkit';
import PetsFormService from '../../services/PetsFormService';
import { PetsCreateFormData } from '../pets-form/types';


export const requestGetMyPets = createAsyncThunk('pets/v1/list/get', async () => {
    const response = await PetsFormService.getMyPets();
    return response;
})

export const requestCreatePet = createAsyncThunk('pets/v1/list/create', async (formData: PetsCreateFormData) => {
    const response = await PetsFormService.createPet(formData);
    return response;
})

export const requestUpdatePet = createAsyncThunk('pets/v1/update', async (formData: PetsCreateFormData) => {
    const response = await PetsFormService.updatePet(formData);
    return response;
})