import { createSlice } from '@reduxjs/toolkit';

import { requestGetMyDocuments } from './asyncActions';

import { DocumentsSliceState } from './types';
import { Status } from '../../types/types';


const initialState: DocumentsSliceState = {
    message: '',
    status: Status.CALM as Status,
    documents: [],
}

const documentsSlice = createSlice({
    name: 'documents',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // Get My Documents
        builder.addCase(requestGetMyDocuments.pending, (state) => {
            state.status = Status.LOADING;
        })
        builder.addCase(requestGetMyDocuments.fulfilled, (state, action) => {
            state.status = Status.SUCCESS;
            if (action.payload) {
                state.documents = action.payload;
            }
        })
        builder.addCase(requestGetMyDocuments.rejected, (state) => {
            state.status = Status.ERROR;
        })
    }
})

export default documentsSlice.reducer;