import { createSlice } from '@reduxjs/toolkit';

import { Status } from '../../types/types';
import { PetsFormSliceState } from './types';
import { requestGetBreeds, requestGetKind } from './asyncActions';


const initialState: PetsFormSliceState = {
    message: '',
    status: Status.CALM as Status,
    kind: [],
    breeds: [],
}

const petsFormSlice = createSlice({
    name: 'pets-form',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // Get Pets Kind
        builder.addCase(requestGetKind.pending, (state) => {
            state.status = Status.LOADING;
        })
        builder.addCase(requestGetKind.fulfilled, (state, action) => {
            if (action.payload && Array.isArray(action.payload)) {
                state.kind = action.payload;
            }
            state.status = Status.SUCCESS;
        })
        builder.addCase(requestGetKind.rejected, (state, action) => {
            state.status = Status.ERROR;
        })
        // Get Pet Breeds
        builder.addCase(requestGetBreeds.pending, (state) => {
            state.status = Status.LOADING;
        })
        builder.addCase(requestGetBreeds.fulfilled, (state, action) => {
            if (action.payload && Array.isArray(action.payload)) {
                state.breeds = action.payload;
            }
            state.status = Status.SUCCESS;
        })
        builder.addCase(requestGetBreeds.rejected, (state, action) => {
            state.status = Status.ERROR;
        })
    }
})

export default petsFormSlice.reducer;