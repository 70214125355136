import { createAsyncThunk } from '@reduxjs/toolkit';

import PetsFormService from '../../services/PetsFormService';


export const requestGetKind = createAsyncThunk('pets/v1/kind', async () => {
    const response = await PetsFormService.getKind();
    return response;
}) 

export const requestGetBreeds = createAsyncThunk('pets/v1/breed/', async (kindId: number) => {
    const response = await PetsFormService.getBreeds(kindId);
    return response;
})