import style from './PetsAdd.module.scss';

// Router
import { useNavigate } from 'react-router-dom';

// Redux
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../redux/store';
import { PetsCreateFormData } from '../../redux/pets-form/types';
import { selectPetsForm } from '../../redux/pets-form/selectors';
import { requestGetBreeds, requestGetKind } from '../../redux/pets-form/asyncActions';

// Telegram
import { BackButton } from '@vkruglikov/react-telegram-web-app';

// Ant Design
import dayjs from 'dayjs';
import locale from 'antd/locale/ru_RU';
import { ArrowRightOutlined } from '@ant-design/icons';
import { ConfigProvider, DatePicker, DatePickerProps, Form, Input, message, Select } from 'antd';

// Components
import Spinner from '../../components/Spinner/Spinner';
import { ButtonMain } from '../../components/Buttons/Buttons';
import { requestCreatePet } from '../../redux/pets/asyncActions';

dayjs.locale('ru-RU');


const PetsAdd = () => {
    const navigate = useNavigate();

    // Alert
    const [messageApi, contextHolder] = message.useMessage();

    // Redux
    const dispatch = useAppDispatch();
    const { kind, breeds } = useSelector(selectPetsForm);

    // Kind
    const [type, setType] = useState<string>();

    const handleAddrTypeChange = (value: string) => {
        setType(value);
        setBreed(undefined);

        getBrands(value);
    };

    // Breed
    const [breed, setBreed] = useState<string>();

    const handleAddBreedChange = (value: string) => {
        setBreed(value);
    };

    const getBrands = (value: string) => {
        const idx = kind.findIndex((el) => el.name === value);
        if (idx !== -1) {
            // Get Breeds By ID
            dispatch(requestGetBreeds(idx + 1))
        }
    }

    // Nickname
    const [nickname, setNickname] = useState<string>();

    // Weight
    const [weight, setWeight] = useState<number | undefined>();

    // Sex
    const [sex, setSex] = useState<string>();

    const handleAddSexChange = (value: string) => {
        setSex(value);
    };

    // Date
    const [date, setDate] = useState<string>();

    const onChangeDate: DatePickerProps['onChange'] = (date, dateString) => {
        if (typeof dateString === 'string') {
            setDate(dateString)
        }
    };

    // Button Submit Disable
    const [disabled, setDisabled] = useState<boolean>(false);

    const handleSubmit = (e: any) => {
        setDisabled(true);
        const errors = [];
        const errorsMsg = [
            'Пожалуйста выберите вид вашего животного',
            'Пожалуйста выберите породу вашего животного',
            'Пожалуйста выберите кличку вашего животного',
            'Пожалуйста выберите вес вашего животного',
            'Пожалуйста выберите пол вашего животного',
            'Пожалуйста выберите Дату Рождения вашего животного',
        ]
        
        const fields = [type, breed, nickname, weight, sex, date];

        for (let i=0; i<fields.length; i++) {
            if (fields[i] === undefined) {
                messageApi.error(errorsMsg[i], 2);
                errors.push(errorsMsg[i]);
                break;
            }
        }

        if (errors.length === 0 && type && breed && nickname) {
            const petMedicalForm = localStorage.getItem('pet_medical_form');
            let formDataCreate: PetsCreateFormData;

            const kindId = kind.findIndex((el) => el.name === type);
            const kindElem = kind[kindId];

            const breedId = breeds.findIndex((el) => el.name === breed);
            const breedElem = breeds[breedId];

            if (petMedicalForm) {
                const petMedicalFormData = JSON.parse(petMedicalForm);
                formDataCreate = {
                    name: nickname,
                    kind: kindElem.id,
                    breed: breedElem.id,
                    kind_name: type,
                    breed_name: breed,
                    birth_date: date,
                    weight: weight,
                    sex: sex,
                    nfc_code: petMedicalFormData.chip_number,
                    peculiar: petMedicalFormData.peculiarities,
                    sterilization: petMedicalFormData.sterilization === 'Да' ? true : false,
                    detention_place: petMedicalFormData.place_detention,
                    detention_conditions: petMedicalFormData.conditions_detention,
                }
            } else {
                formDataCreate = {
                    name: nickname,
                    kind: kindElem.id,
                    breed: breedElem.id,
                    kind_name: type,
                    breed_name: breed,
                    birth_date: date,
                    weight: weight,
                    sex: sex,
                }
            }
            dispatch(requestCreatePet(formDataCreate));
            setTimeout(() => {
                setDisabled(false);
                navigate('/home/pets');
            }, 1000)
        } else {
            setDisabled(false);
        }
    }
      
    const handleWriteMedicalCard = (e: any) => {
        e.preventDefault();
        
        // Add To LocalStorage Data
        if (type || breed || nickname || weight || sex ||date) {
            localStorage.setItem('pet_form', JSON.stringify({
                'type': type ,
                'breed': breed,
                'nickname': nickname,
                'weight': weight,
                'sex': sex,
                'date': date,
            }))
        }

        // Navigate to Medical Form
        navigate('/home/pets/add/medical_card');
    }

    useEffect(() => {
        if (kind.length === 0) {
            dispatch(requestGetKind());
        }
    }, [dispatch, kind])

    // Locas Storage Get
    const petForm = localStorage.getItem('pet_form');
    useEffect(() => {
        if (petForm && kind.length > 0) {
            const petFormData = JSON.parse(petForm);

            // Get Breeds By Type
            getBrands(petFormData.type);

            // Setup Values
            setType(petFormData.type);
            setBreed(petFormData.breed);
            setNickname(petFormData.nickname);
            setWeight(petFormData.weight);
            setSex(petFormData.sex);
            setDate(petFormData.date);
        }
    }, [kind])

    // Handle Back Button Telegram
    const handleClickBack = () => {
        navigate('/home/pets');
    }

    return (
        <div className={style.pets_add}>
            <BackButton onClick={handleClickBack} />
            {contextHolder}

            {type || !petForm
                ? (
                    <Form
                        labelCol={{ span: 4 }}
                        wrapperCol={{ span: 10 }}
                        layout="horizontal"
                        style={{ maxWidth: 300, margin: '0 auto' }}
                    >
                        <Form.Item className={style.type} name={'kind'}>
                            <Select
                                placeholder='Вид'
                                defaultValue={type}
                                onSelect={(value) => handleAddrTypeChange(value)}
                            >
                                {kind.map((type) =>(
                                    <Select.Option key={type.id} value={type.name}>
                                        {type.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item className={style.breed} name={'breed'}>
                            <Select
                                disabled={type ? false : true}
                                placeholder='Порода'
                                defaultValue={breed}
                                onSelect={(value) => handleAddBreedChange(value)}
                            >
                                {breeds.map((type) =>(
                                    <Select.Option key={type.id} value={type.name}>{type.name}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item className={style.nickname}>
                            <Input onChange={(e) => setNickname(e.target.value)} value={nickname} placeholder='Кличка' />
                        </Form.Item>
                        <div className={style.pets__flex}>
                            <Form.Item style={{maxWidth: '140px'}} className={style.weight}>
                                <Input value={weight} onChange={(e) => setWeight(Number(e.target.value))} type='number' placeholder='Вес, Кг' />
                            </Form.Item>
                            <Form.Item style={{minWidth: '140px'}} className={style.sex}>
                                <Select value={sex} placeholder='Пол' onSelect={(value) => handleAddSexChange(value)}>
                                    <Select.Option value="М">М</Select.Option>
                                    <Select.Option value="Ж">Ж</Select.Option>
                                </Select>
                            </Form.Item>
                        </div>
                        <Form.Item className={style.date}>
                            <ConfigProvider locale={locale}>
                                <DatePicker value={date ? dayjs(date) : undefined} style={{maxWidth: 300, minWidth: 300}} onChange={onChangeDate} />
                            </ConfigProvider>
                        </Form.Item>

                        <div onClick={handleWriteMedicalCard} className={style.pets__medical_link}>
                            <p>Заполнить медкарту</p>
                            <ArrowRightOutlined />
                        </div>

                        <div onClick={handleSubmit} className={style.button_pets_form}>
                            <ButtonMain disabled={disabled} text='Добавить' />
                        </div>
                    </Form>
                )
                : (
                    <Spinner />
                )
            }
        </div>
    )
}

export default PetsAdd;