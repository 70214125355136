import { createSlice } from '@reduxjs/toolkit';

import { InviteSliceState, Status } from './types';
import { requestGetInviteCode, requestGetInviteReferral } from './asyncActions';


const initialState: InviteSliceState = {
    message: '',
    status: Status.CALM as Status,
    invite_code: null,
    refferal_code: null,
}

const inviteSlice = createSlice({
    name: 'invite',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // Get My Invite Code
        builder.addCase(requestGetInviteCode.pending, (state) => {
            state.status = Status.LOADING;
        })
        builder.addCase(requestGetInviteCode.fulfilled, (state, action) => {
            if (action.payload) {
                state.status = Status.SUCCESS;
                state.invite_code = action.payload.invite_code;
            } else {
                state.status = Status.ERROR;
                state.message = 'Произошла ошибка при получении инвайт кода';
            }
        })
        builder.addCase(requestGetInviteCode.rejected, (state) => {
            state.status = Status.ERROR;
        })
        // Get Refferal Invite Code
        builder.addCase(requestGetInviteReferral.pending, (state) => {
            state.status = Status.LOADING;
        })
        builder.addCase(requestGetInviteReferral.fulfilled, (state, action) => {
            if (action.payload) {
                if (action.payload.invite_code) {
                    document.cookie = `invited_by=${action.payload.invite_code}`;
                }
            }
            state.status = Status.CALM;
        })
        builder.addCase(requestGetInviteReferral.rejected, (state) => {
            state.status = Status.ERROR;
        })
    }
})

export default inviteSlice.reducer;