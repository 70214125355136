import style from './Pets.module.scss';

// React
import { useEffect } from 'react';

// Images
import imgPets from '../../assets/img/pets/icon.png';
import imgAnimal from '../../assets/img/pets/cil-animal.svg';

// Ant Design
import { RightOutlined } from '@ant-design/icons';

// Router
import { Link } from 'react-router-dom';

// Redux
import { useSelector } from 'react-redux';
import { Status } from '../../types/types';
import { useAppDispatch } from '../../redux/store';
import { requestGetMyPets } from '../../redux/pets/asyncActions';

// Components
import { ButtonMain } from '../../components/Buttons/Buttons';
import { selectPets } from '../../redux/pets/selectors';
import Spinner from '../../components/Spinner/Spinner';


const Pets = () => {
    // Redux
    const dispatch = useAppDispatch();
    const { status, pets } = useSelector(selectPets);

    useEffect(() => {
        if (status === Status.CALM && pets.length === 0) {
            dispatch(requestGetMyPets());
        }
    }, [dispatch, pets])

    useEffect(() => {
        localStorage.removeItem('pet_medical_form');
        localStorage.removeItem('pet_form');
    }, [])
    
    return (
        <div className={`${style.pets} ${pets.length > 0 && style.pets_list}`}>
            {status === Status.LOADING || status === Status.CALM
                ? <Spinner />
                : (
                    <>
                    {pets.length > 0
                        ? (
                            pets.map((pet, idx) => (
                                <Link to={`/home/pets/profile/${pet.id}`} key={idx} className={style.pet}>
                                    <div className={style.pet__img}>
                                        <img src={imgAnimal} alt={`animal-${pet.id}`} />
                                    </div>
                                    <div className={style.pet__name}>
                                        <p>{pet.name}</p>
                                        <p>{pet.kind_name}</p>
                                    </div>
                                    <RightOutlined />
                                </Link>
                            ))
                        )
                        : (
                            <>
                            <img src={imgPets} alt="img-pets" />
                            <p>У вас пока нет добавленных питомцев</p>
                            </>
                        )}
                        <Link to={'/home/pets/add'}>
                            <ButtonMain text='Добавить' />
                        </Link>
                    </>
                )}
        </div>
    )
}

export default Pets;