// React
import { useEffect } from 'react';

// Redux
import { useSelector } from 'react-redux';
import { Status } from '../../../types/types';
import { useAppDispatch } from '../../../redux/store';
import { selectPackages } from '../../../redux/packages/selectors';
import { requestPackages } from '../../../redux/packages/asyncActions';

// Components
import Spinner from '../../Spinner/Spinner';
import { Service } from '../Service/Service';


const Packages = () => {
    // Get Packages
    const { status, packages } = useSelector(selectPackages);

    // Redux
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (packages.length === 0 && status === Status.CALM) {
            dispatch(requestPackages());
        }
    }, [dispatch, packages, status])

    return (
        <>
            {packages.length === 0
                ? (
                    <Spinner />
                )
                : (
                    packages.map((pkg, idx) => (
                        <Service
                            key={idx}
                            sub_text=''
                            alias={pkg.alias}
                            price={pkg.price}
                            text={pkg.verbose_name}
                            icon={pkg.image_thumbnail}
                        />
                    ))
                )}
        </>
    )
}

export default Packages;