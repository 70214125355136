import style from './layouts.module.scss';

// Router
import { Outlet, useLocation } from 'react-router-dom';

// Components
import Navbar from '../components/Navbar/Navbar';


type PagesType = {
    pathname: string;
    title: string;
}

const pages: PagesType[] = [
    {
        title: 'Медкарта',
        pathname: '/home/pets/profile/medical_card/',
    },
    {
        title: '',
        pathname: '/home/pets/profile/'
    },
    {
        title: 'Документы',
        pathname: '/home/documents'
    },
    {
        title: 'Чаты',
        pathname: '/home/chats'
    },
    {
        title: 'Медкарта',
        pathname: '/home/pets/add/medical_card',
    },
    {
        title: 'Добавить питомца',
        pathname: '/home/pets/add',
    },
    {
        title: 'Питомцы',
        pathname: '/home/pets',
    },
    {
        title: 'Акции',
        pathname: '/home/more',
    },
    {
        title: 'Поддержка',
        pathname: '/support',
    },
    {
        title: 'О приложении',
        pathname: '/about',
    },
    {
        title: 'Условия акции',
        pathname: '/home/invite-friend/terms'
    },
    {
        title: 'Приглашайте друзей в приложении VetPet',
        pathname: '/home/invite-friend',
    },
    {
        title: 'Оплата',
        pathname: '/home/service-pay/',
    },
    {
        title: '',
        pathname: '/home/profile',
    },
    {
        title: '',
        pathname: '/home'
    },
    {
        title: '',
        pathname: '/'
    },
]


export function TitleLayout() {
    const { pathname } = useLocation();

    const currentPage = pages.find(page => pathname.includes(page.pathname));

    return (
        <div className={style.layout_title}
             style={
                pathname === '/' ||
                pathname === '/home' ||
                pathname === '/home/' ||
                pathname === '/home/profile' ||
                pathname.includes('/home/pets/profile') ? {padding: '0'} : {padding: '20px 25px'}}>
            <h1 className={style.header_text}>{currentPage ? currentPage.title : 'Страница не найдена'}</h1>
            <Outlet />
            <Navbar />
        </div>
    )
}

export function WithoutNavbarLayout() {
    const { pathname } = useLocation();

    const currentPage = pages.find(page => pathname.includes(page.pathname));

    return (
        <div className={style.layout_title}>
            <h1 className={style.header_text}>{currentPage ? currentPage.title : 'Страница не найдена'}</h1>
            <Outlet />
        </div>
    )
}