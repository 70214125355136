//@ts-nocheck
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Pet, PetsSliceState } from './types';
import { Status } from '../../types/types';
import { requestGetMyPets, requestCreatePet, requestUpdatePet } from './asyncActions';


const initialState: PetsSliceState = {
    message: '',
    status: Status.CALM as Status,
    pets: [],
}

const petsSlice = createSlice({
    name: 'pets',
    initialState,
    reducers: {
        setPetsIsNull: (state): void => {
            state.pets = [];
            state.status = Status.CALM;
        }
    },
    extraReducers: (builder) => {
        // Get My Pets
        builder.addCase(requestGetMyPets.pending, (state) => {
            state.status = Status.LOADING;
        })
        builder.addCase(requestGetMyPets.fulfilled, (state, action) => {
            if (action.payload && Array.isArray(action.payload)) {
                state.pets = action.payload;
            }
            state.status = Status.SUCCESS;
        })
        builder.addCase(requestGetMyPets.rejected, (state, action) => {
            state.status = Status.ERROR;
        })
        // Create Pet
        builder.addCase(requestCreatePet.pending, (state) => {
            state.status = Status.LOADING;
        })
        builder.addCase(requestCreatePet.fulfilled, (state, action: PayloadAction<Pet>) => {
            // Delete From Local Storage
            if (action.payload) {
                localStorage.removeItem('pet_form');
                localStorage.removeItem('pet_medical_form');
                state.status = Status.CALM;
                state.pets = [];
            }
        })
        builder.addCase(requestCreatePet.rejected, (state, action) => {
            state.status = Status.ERROR;
        })
        // Update Pet
        builder.addCase(requestUpdatePet.pending, (state) => {
            state.status = Status.LOADING;
        })
        builder.addCase(requestUpdatePet.fulfilled, (state, action: PayloadAction<Pet>) => {
            if (action.payload) {
                localStorage.removeItem('pet_form');
                localStorage.removeItem('pet_medical_form');
                // Update Pet
                const idx = state.pets.findIndex((el) => el.id === action.payload.id);
                if (idx !== -1) {
                    state.pets[idx] = action.payload;
                }
            }
            state.status = Status.SUCCESS;
        })
        builder.addCase(requestUpdatePet.rejected, (state, action) => {
            state.status = Status.ERROR;
        })
    }
});

export const { setPetsIsNull } = petsSlice.actions;

export default petsSlice.reducer;