import style from './Chats.module.scss';

import { useEffect, useState } from 'react';

// Date
import Moment from 'moment';

// Ant Design
import { Form, Input, Modal } from 'antd';
import { message as antdMessage } from 'antd';
import { CaretDownOutlined, CaretUpOutlined, RightCircleFilled } from '@ant-design/icons';

// Router
import { useNavigate } from 'react-router-dom';

// Redux
import { useSelector } from 'react-redux';
import { Status } from '../../types/types';
import { useAppDispatch } from '../../redux/store';
import { selectMyChats } from '../../redux/chats/selectors';
import { requestGetChats } from '../../redux/chats/asyncActions';
import { selectMyPackages } from '../../redux/my-packages/selectors';
import { selectMyServices } from '../../redux/my-services/selectors';
import { setMessage, setRequestId } from '../../redux/my-services/slice';
import { requestActiveService } from '../../redux/my-services/asyncActions';
import { requestMyActivePackages, requestMyInactivePackages } from '../../redux/my-packages/asyncActions';

// Components
import { ButtonMain } from '../../components/Buttons/Buttons';


const Chats = () => {
    const [isCurrentChat, setIsCurrentChat] = useState<boolean>(false);
    const [isArchiveChat, setIsArchiveChat] = useState<boolean>(false);

    // Navigate
    const navigate = useNavigate();

    // Redux
    const dispatch = useAppDispatch();

    // Get Active / Inactive Packages
    const { status, active_packages, inactive_packages } = useSelector(selectMyPackages);

    useEffect(() => {
        if (!active_packages && !inactive_packages) {
            dispatch(requestMyActivePackages());
            dispatch(requestMyInactivePackages());
        }
    }, [active_packages, inactive_packages, dispatch, status])

    const handleOpenChat = (chatId: number, type: string) => {
        navigate(`/home/chat/${chatId}/${type}`);
    }

    // Show Alert
    const [messageApi, contextHolder] = antdMessage.useMessage();

    // Modal Start Package
    const [form] = Form.useForm();
    const [serviceId, setServiceId] = useState<number>();
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleOpenModal = (idx_service: number) => {
        setIsModalOpen(true);
        setServiceId(idx_service);
    }

    const handleStart = (e: any) => {
        e.preventDefault();
        if (serviceId) {
            form
            .validateFields()
            .then((values) => {
                form.resetFields();
    
                const question = values['question'];
    
                // Activate Service
                dispatch(requestActiveService({
                    comment: question,
                    service_id: serviceId,
                }))
    
            })
            .catch(() => {});
        } else {
            messageApi.info('Произошла ошибка при выборе консультации', 2);
        }
    }

    const { message, request_id } = useSelector(selectMyServices);
    useEffect(() => {
        if (message.length > 0) {
            if (request_id) {
                navigate(`/home/chat/${request_id}/active`);
            } else {
                messageApi.error(message, 2);
            }
        }

        return () => {
            dispatch(setMessage(''));
            dispatch(setRequestId(null));
        }
    }, [message, request_id, dispatch, messageApi, navigate])

    const { statusChat, chats, closedChats } = useSelector(selectMyChats);

    // Get My Chats
    useEffect(() => {
        if (!chats.length && statusChat === Status.CALM) {
            dispatch(requestGetChats());
        }
    }, [chats, statusChat, dispatch])

    // Handle Add Chat
    const handleAddButton = () => {
        navigate('/home');
    }

    return (
        <div className={style.chats}>
            {contextHolder}
            <Modal
                title={'Ваш Вопрос'}
                okText="Старт"
                open={isModalOpen}
                onCancel={handleCancel}
                onOk={handleStart}
            >
                <Form form={form} layout="vertical">
                    <Form.Item
                        name="question"
                        rules={[
                            { required: true, message: "Пожалуйста введите вопрос" }
                        ]}
                    >
                        <Input placeholder='Введите вопрос' />
                    </Form.Item>
                </Form>
            </Modal>
            <div className={style.current_chats}>
                <div onClick={() => setIsCurrentChat(!isCurrentChat)}>
                    {isCurrentChat ? <CaretUpOutlined /> : <CaretDownOutlined />}
                    <p>Текущие</p>
                </div>
                {isCurrentChat && (
                    active_packages && active_packages.length > 0 || chats.length > 0
                        ? (
                            <div className={style.my_packages}>
                                {chats && chats.map((chat, idx) => (
                                        <div
                                            className={style.my_package}
                                            onClick={() => handleOpenChat(chat.request_id, 'active')}
                                            key={idx}
                                        >
                                            <p className={style.inactive_text}>{chat.service_name}</p>
                                            <RightCircleFilled />
                                        </div>
                                ))}
                                {active_packages && active_packages.map((pkg, idx) => (
                                    <div
                                        className={style.my_package}
                                        onClick={() => handleOpenModal(pkg.services[0].id ? pkg.services[0].id : 0)}
                                        key={idx}
                                    >
                                        <div className={style.active_package_text}>
                                            <p>{pkg.verbose_name}</p>
                                            <p>{Moment(pkg.dt_start).format('DD.MM.YYYY')}</p>
                                        </div>
                                        <RightCircleFilled />
                                    </div>
                                ))}
                            </div>
                        )
                        : (
                            <p className={style.description}>
                                У вас нет активных услуг
                            </p>
                        )
                )}
            </div>
            <div className={style.arhive_chats}>
                <div onClick={() => setIsArchiveChat(!isArchiveChat)}>
                    {isArchiveChat ? <CaretUpOutlined /> : <CaretDownOutlined />}
                    <p>Архив</p>
                </div>
                {isArchiveChat && (
                    closedChats.length > 0
                        ? (
                            <div className={style.my_packages}>
                                {closedChats && closedChats.map((chat, idx) => (
                                        <div
                                            className={style.my_package}
                                            onClick={() => handleOpenChat(chat.request_id, 'inactive')}
                                            key={idx}
                                        >
                                            <p className={style.inactive_text}>{chat.service_name}</p>
                                            <RightCircleFilled />
                                        </div>
                                ))}
                            </div>
                        )
                        : (
                            <p className={style.description}>
                                Вы еще не пользовались нашими услугами
                            </p>
                        )
                )}
            </div>
            <div onClick={handleAddButton}>
                <ButtonMain text='Добавить' />
            </div>
        </div>
    )
}

export default Chats;