import style from './ServiceInfo.module.scss';

// Redux
import { useSelector } from 'react-redux';
import { selectPackages } from '../../redux/packages/selectors';

// Telegram
import { BackButton, MainButton, useWebApp, WebAppUser } from '@vkruglikov/react-telegram-web-app';

// Component
import Spinner from '../../components/Spinner/Spinner';
import { useAppDispatch } from '../../redux/store';
import { setActiveIsNull } from '../../redux/packages/slice';
import { useNavigate } from 'react-router-dom';
import { selectInviteCode } from '../../redux/invite-code/selectors';
import { selectAuth } from '../../redux/auth/selectors';
import { Status } from '../../types/types';
import { getInviteReferralFormData } from '../../redux/invite-code/types';
import { useEffect } from 'react';
import { requestGetInviteReferral } from '../../redux/invite-code/asyncActions';


const ServiceInfo = () => {
    const navigate = useNavigate();

    // Telegram
    const WebApp = useWebApp();

    const user: WebAppUser = WebApp.initDataUnsafe.user;

    // Redux
    const dispatch = useAppDispatch();
    const { activePackage } = useSelector(selectPackages);

    // Get Referral Code
    const { refferal_code } = useSelector(selectInviteCode);

    const { statusAuth } = useSelector(selectAuth);

    useEffect(() => {
        if (statusAuth === Status.SUCCESS && !refferal_code) {
            const formData: getInviteReferralFormData = {
                telegram_id: user.id
            }
            dispatch(requestGetInviteReferral(formData));
        }
    }, [dispatch, statusAuth, refferal_code])

    // Handle Back
    const handleClickBack = () => {
        dispatch(setActiveIsNull());
        navigate('/home');
    };

    // Handle Go Checkout Button
    const handleClickGoCheckout = () => {
        navigate(`/home/service-pay/${activePackage?.alias}`);
    }

    return (
        <div className={style.service_info}>
            <BackButton onClick={handleClickBack} />
            {activePackage
                ? (
                    <>
                        <h1>{activePackage.verbose_name}</h1>
                        <img src={activePackage.image_thumbnail} alt="img-service" />
                        <p>{activePackage.price} рублей</p>
                        
                        <MainButton onClick={handleClickGoCheckout} color='#8DE719' text='ОФОРМИТЬ' />
                    </>
                )
                : (
                    <Spinner />
                )}
        </div>
    )
}

export default ServiceInfo;