import $api from '../http/index';

import { PetsCreateFormData } from '../redux/pets-form/types';


export default class PetsFormService {
    static async getKind() {
        const res = await $api.get('pets/v1/kind')
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            return err.response.data;
        })
        return res;
    }
    static async getBreeds(kindId: number) {
        const res = await $api.get(`pets/v1/breed/${kindId}`)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            return err.response.data;
        })
        return res;
    }
    static async createPet(formData: PetsCreateFormData) {
        const res = await $api.post('pets/v1/list', formData)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            return err.response.data;
        })
        return res;
    }
    static async updatePet(formData: PetsCreateFormData) {

        const res = await $api.put(`pets/v1/${formData.id}`, {
            name: formData.name,
            kind: formData.kind,
            breed: formData.breed,
            kind_name: formData.kind_name,
            breed_name: formData.breed_name,
            birth_date: formData.birth_date,
            weight: formData.weight,
            nfc_code: formData.nfc_code,
            peculiar: formData.peculiar,
            sterilization: formData.sterilization,
            detention_place: formData.detention_place,
            detention_conditions: formData.detention_conditions,
            sex: formData.sex,
        })
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            return err.response.data;
        })
        return res;
    }
    static async getMyPets() {
        const res = await $api.get('pets/v1/list')
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            return err.response.data;
        })
        return res;
    }
}