import style from './MessageText.module.scss';


type Props = {
    text: string;
}

export const MessageTextSender = ({ text }: Props) => {
    return (
        <div className={style.message__text_sender}>
            <p>{text}</p>
        </div>
    )
}


export const MessageTextReceiver = ({ text }: Props) => {
    return (
        <div className={style.message__text_receiver}>
            <p>{text}</p>
        </div>
    )
}