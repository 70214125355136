import { createSlice } from '@reduxjs/toolkit';

import { Status } from '../../types/types';
import { MyPackagesSliceState } from './types';

import { requestMyActivePackages, requestMyInactivePackages } from './asyncActions';



const initialState: MyPackagesSliceState = {
    message: '',
    active_packages: null,
    inactive_packages: null,
    status: Status.CALM as Status,
}

const myPackagesSlice = createSlice({
    name: 'my_packages',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // Get Active Packages
        builder.addCase(requestMyActivePackages.pending, (state) => {
            state.status = Status.LOADING;
        })
        builder.addCase(requestMyActivePackages.fulfilled, (state, action) => {
            if (action.payload && Array.isArray(action.payload)) {
                state.active_packages = action.payload;
            }
            state.status = Status.SUCCESS;
        })
        builder.addCase(requestMyActivePackages.rejected, (state, action) => {
            state.status = Status.ERROR;
        })
        // Get Inactive Packages
        builder.addCase(requestMyInactivePackages.pending, (state) => {
            state.status = Status.LOADING;
        })
        builder.addCase(requestMyInactivePackages.fulfilled, (state, action) => {
            if (action.payload && Array.isArray(action.payload)) {
                state.inactive_packages = action.payload;
            }
            state.status = Status.SUCCESS;
        })
        builder.addCase(requestMyInactivePackages.rejected, (state, action) => {
            state.status = Status.ERROR;
        })
    }
})

export default myPackagesSlice.reducer;