import style from './Welcome.module.scss';

// Images
import imgOne from '../../assets/img/welcome/1.svg';
import imgTwo from '../../assets/img/welcome/2.svg';
import imgThree from '../../assets/img/welcome/3.svg';
import imgFour from '../../assets/img/welcome/4.svg';

// Router
import { useNavigate } from 'react-router-dom';

// Redux
import { useAppDispatch } from '../../redux/store';
import { AuthFormData } from '../../redux/auth/types';
import { requestAuth } from '../../redux/auth/asyncActions';

// React
import { useEffect, useState } from 'react';

// Telegram
import { useWebApp, WebAppUser } from '@vkruglikov/react-telegram-web-app';

import welcomeLogo from '../../assets/img/welcome/welcome_logo.png';
import { ButtonMain } from '../../components/Buttons/Buttons';


const Welcome = () => {
    // Navigate
    const navigate = useNavigate();
    
    // Telegram
    const WebApp = useWebApp();

    const user: WebAppUser = WebApp.initDataUnsafe.user;
    const [isClearLocalStorage, setIsClearLocalStorage] = useState<boolean>(false);

    // Redux
    const dispatch = useAppDispatch();

    // Get Token
    if (!isClearLocalStorage) {
        localStorage.clear();
        setIsClearLocalStorage(true);
    }
    const token = localStorage.getItem('token');

    useEffect(() => {
        if (!token && user?.id) {
            const formData: AuthFormData = {
                'telegram_id': user.id ? `${user.id}` : null,
                'first_name': user.first_name ? user.first_name : null,
                'last_name': user.last_name ? user.last_name : null,
                'username': user.username ? user.username : null,
            }
            dispatch(requestAuth(formData));
        }
    }, [dispatch, token, WebApp, user])

    const handleContinueBtn = () => {
        navigate('/home');
    }

    return (
        <div className={style.welcome_page}>
            <div className={style.welcome_page__logo}>
                <img src={welcomeLogo} alt="welcome-logo" />
            </div>

            <div className={style.welcome_info__services}>
                <ul>
                    <li>
                        <img src={imgOne} alt="check-mark" />
                        <p>Экономия Вашего времени и денег</p>
                    </li>
                    <li>
                        <img src={imgTwo} alt="check-mark" />
                        <p>Индивидуальный подход и конфиденциальность</p>
                    </li>
                    <li>
                        <img src={imgThree} alt="check-mark" />
                        <p>Диагностика без стресса для животного</p>
                    </li>
                    <li>
                        <img src={imgFour} alt="check-mark" />
                        <p>Профессиональные ветеринары в Вашем телефоне</p>
                    </li>
                </ul>
            </div>
            <div onClick={handleContinueBtn} className={style.welcome_page__btn}>
                <ButtonMain text='Продолжить' />
            </div>
        </div>
    )
}

export default Welcome;