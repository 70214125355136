import $api from '../http/index';

export default class DocumentsService {
    static async getDocuments() {
        const res = await $api.get('client/v1/files')
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            return err.response.data;
        })
        return res;
    }
}