import style from './Chat.module.scss';

// React
import { useEffect, useRef, useState } from 'react';

// Date
import moment from 'moment';

// Router
import { useNavigate, useParams } from 'react-router-dom';

// Ant Design
import { message as antdMessage } from 'antd';
import { Upload, UploadFile, UploadProps } from 'antd';
import { AudioOutlined, BorderOutlined, PaperClipOutlined, SendOutlined } from '@ant-design/icons';

// Telegram
import { BackButton } from '@vkruglikov/react-telegram-web-app';

// Redux
import { useSelector } from 'react-redux';
import { Status } from '../../types/types';
import { useAppDispatch } from '../../redux/store';
import { setMessage } from '../../redux/auth/slice';
import { selectMyChats } from '../../redux/chats/selectors';
import { AddNewImageToState } from '../../redux/chats/types';
import { requestMyActivePackages, requestMyInactivePackages } from '../../redux/my-packages/asyncActions';
import { addNewImageMessage, addNewTextMessage, clearActiveChat, clearFileIds } from '../../redux/chats/slice';
import { requestCreateMessage, requestGetChats, requestGetMessages, requestUploadFile } from '../../redux/chats/asyncActions';

// Audio Recording
import { useAudioRecorder } from 'react-audio-voice-recorder';

// Components
import Messages from '../../components/Chat/Messages/Messages';
import UploadImage from '../../components/Chat/UploadImage/UploadImage';
import AudioRecord from '../../components/Chat/AudioRecord/AudioRecord';

// Utils
import { getFormatFile } from '../../utils/getFormatFile';


const Chat = () => {
    // Router
    const navigate = useNavigate();
    const { chatId, type } = useParams();

    // Autdio Record
    const [isRecord, setIsRecord] = useState<boolean>(false);

    // Message Text
    const [messageText, setMessageText] = useState<string>('');

    // Redux
    const dispatch = useAppDispatch();
    const { statusChat, activeChat, file_ids } = useSelector(selectMyChats);

    // Get Messages
    useEffect(() => {
        let intervalId: any = null;

        if (chatId && (statusChat === Status.CALM || statusChat !== Status.ERROR)) {
            intervalId = setInterval(() => {
                dispatch(requestGetMessages(chatId))
            }, 2000)
        }

        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        }
    })

    // Clear File IDS, Active Chat by Unmound
    useEffect(() => {
        return () => {
            dispatch(clearFileIds());
            dispatch(clearActiveChat());
            dispatch(requestGetChats());
            dispatch(requestMyActivePackages());
            dispatch(requestMyInactivePackages());
        }
    }, [dispatch])

    // Sending Messages
    function addNewMessageText() {
        if (messageText.length > 0) {
            dispatch(addNewTextMessage(messageText));
        }

        // Add Files
        setTimeout(() => {
            fileList.forEach(async (file) => {
                const formData: AddNewImageToState = {
                    type: getFormatFile(file.name),
                    link: file.url,
                    value: file.name,
                }
                dispatch(addNewImageMessage(formData));
            })
        }, 500)

        if (chatId && activeChat) {
            dispatch(requestCreateMessage({
                chat_id: Number(chatId),
                content: messageText,
                file_ids: file_ids,
            }))
            setMessage('');
            setFileList([]);
            dispatch(clearFileIds());
            scrollBottom();
        }
    }

    // Send Text Message
    const handleCreateMessage = (e: any) => {
        e.preventDefault();
        if (statusChat === Status.SUCCESS) {
            addNewMessageText();
        } else {
            setTimeout(() => {
                addNewMessageText();
            }, 2000)
        }
        setMessageText('');
    }

    // Upload Images
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    async function getBase64(file: any) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => {
                resolve(reader.result)
            }
            reader.onerror = reject
        })
    }

    const props: UploadProps = {
        beforeUpload: async (file: any) => {
            const res: any = await getBase64(file);
            file.url = res;
            setFileList([...fileList, file]);

            dispatch(requestUploadFile({
                file: res,
                file_name: file.name,
            }))
            return false
        },
    };

    // Show Alert
    const [messageApi, contextHolder] = antdMessage.useMessage();

    // Audio Record
    const [isStop, setIsStop] = useState<boolean>(false);
    const [elapsedTime, setElapsedTime] = useState<number>(0);
    const [isRunning, setIsRunning] = useState<boolean>(false);
    const [voiceFileName, setVoiceFileName] = useState<string>('');
    const [recordBlobLink, setRecordBlobLink] = useState<any>(null);

    const clearHandle = () => {
        setIsRunning(false)
        setRecordBlobLink(null)
        setElapsedTime(0)
        setIsRecord(false);
        setIsStop(false);
        dispatch(clearFileIds());
    }

    const {
        startRecording,
        stopRecording,
        recordingBlob,
        isRecording,
        recordingTime,
    } = useAudioRecorder({
        
    }, (err) => {
        messageApi.info(`Вы не дали приложению права на запись через микрофон.`, 2);
        clearHandle();
    });

    const handleClickVoice = () => {
        setElapsedTime(0);
        setIsRunning(true);
        setIsStop(false);
        setIsRecord(true);

        startRecording();
    }

    const handleClickVoiceStop = () => {
        stopRecording();
        setIsStop(true);
    }

    function addNewImage(formData: AddNewImageToState) {
        dispatch(addNewImageMessage(formData));

        clearHandle();

        dispatch(requestCreateMessage({
            chat_id: Number(chatId),
            content: messageText,
            file_ids: file_ids,
        }))
        setMessage('');
        setFileList([]);
        dispatch(clearFileIds());
        scrollBottom();
    }

    const handleCreateMessageVoice = async (e: any) => {
        const formData: AddNewImageToState = {
            type: 'file',
            link: recordBlobLink,
            value: voiceFileName,
        }

        if (statusChat === Status.SUCCESS) {
            addNewImage(formData);
        } else {
            setTimeout(() => {
                addNewImage(formData);
            }, 2000);
        }
        scrollBottom();
    }

    const uploadVoiceFile = async (recordingBlob: Blob) => {
        const randomNum = Math.floor(Math.random() * 3000000);
        const voiceFileName = `${moment().format("dddd, MMMM Do YYYY, h:mm:ss")}_${randomNum}.webm`;
        const voiceFile = new File([recordingBlob], voiceFileName, { type: "audio/webm" });
        const res: any = await getBase64(voiceFile);

        // Setup Info Voice File
        setVoiceFileName(voiceFileName);

        dispatch(requestUploadFile({
            file: res,
            file_name: voiceFile.name,
        }))
    }

    useEffect(() => {
        if (!recordingBlob) return;
        const url = URL.createObjectURL(recordingBlob);
        if (url) {
            uploadVoiceFile(recordingBlob);
        }
        setRecordBlobLink(url);
    }, [recordingBlob])

    // Back Telegram Button
    const handleClickBack = () => {
        navigate('/home/chats')
    }

    // Scroll to Bottom
    const messagesEndRef = useRef<null | HTMLDivElement>(null)

    const scrollBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    return (
        <div className={style.chat}>
            {contextHolder}
            <BackButton onClick={handleClickBack} />
            <div className={style.chat__messages}>
                <Messages activeChat={activeChat} messagesEndRef={messagesEndRef} scrollBottom={scrollBottom} />
            </div>
            {fileList.length > 0 && (
                <div className={style.uploaded_images}>
                    <UploadImage fileList={fileList} setFileList={setFileList} props={props} />
                </div>
            )}
            {type === 'active' && (
                <div className={isRecord ? style.record_handler : style.chat__control_block} style={isStop ? { padding: '9px 18px' } : {}}>
                    {isRecord
                        ? (
                            <AudioRecord
                                isRunning={isRunning}
                                elapsedTime={elapsedTime}
                                recordBlobLink={recordBlobLink}
                                setElapsedTime={setElapsedTime}
                                clearHandle={clearHandle}
                                recordingTime={recordingTime}
                            />
                        )
                        : (
                            <>
                                <Upload {...props} showUploadList={false}>
                                    <PaperClipOutlined />
                                </Upload>
                                <input
                                    type="text"
                                    value={messageText}
                                    placeholder='Введите ваше сообщение'
                                    onChange={(e) => setMessageText(e.target.value)}
                                />
                            </>
                        )
                    }
                    {messageText.length > 0 || fileList.length > 0
                        ? <SendOutlined onClick={handleCreateMessage} />
                        : isRecord
                            ? !isStop
                                ? <BorderOutlined onClick={handleClickVoiceStop} />
                                : <SendOutlined onClick={handleCreateMessageVoice} />
                            : <AudioOutlined onClick={handleClickVoice} />}
                </div>
            )}
        </div>
    )
}

export default Chat;