import style from './PetsAddMedical.module.scss';

// React
import { useEffect, useState } from 'react';

// Router
import { useNavigate } from 'react-router-dom';

// Ant Design
import { Form, Input, Select } from 'antd';

import { BackButton } from '@vkruglikov/react-telegram-web-app';

// Components
import Spinner from '../../components/Spinner/Spinner';
import { ButtonMain } from '../../components/Buttons/Buttons';


const PetsAddMedical = () => {
    const navigate = useNavigate();

    // Sterilization
    const [sterilization, setSterilization] = useState<string>();

    const handleAddSexChange = (value: string) => {
        setSterilization(value);
    };

    // Place Detention
    const [placeDetention, setPlaceDetention] = useState<string>();

    // Conditions Detention
    const [conditionsDetention, setConditionsDetention] = useState<string>();

    // Chip number
    const [chipNumber, setChipNumber] = useState<string>();

    // Peculiarities
    const [peculiarities, setPeculiarities] = useState<string>();


    // Handle Add To LocalStorage and Redirect to Pets Add Form
    const handleSaveMedicalForm = (e: any) => {
        e.preventDefault();

        // Add To LocalStorage Data
        if (sterilization || placeDetention || conditionsDetention || chipNumber || peculiarities) {
            localStorage.setItem('pet_medical_form', JSON.stringify({
                'sterilization': sterilization,
                'place_detention': placeDetention,
                'conditions_detention': conditionsDetention,
                'chip_number': chipNumber,
                'peculiarities': peculiarities,
            }));
        }

        // Navigate to Pets Add Form
        navigate('/home/pets/add');
    }

    // Locas Storage Get
    const petMedicalForm = localStorage.getItem('pet_medical_form');
    useEffect(() => {
        if (petMedicalForm) {
            const petMedicalFormData = JSON.parse(petMedicalForm);

            setChipNumber(petMedicalFormData.chip_number);
            setSterilization(petMedicalFormData.sterilization);
            setPeculiarities(petMedicalFormData.peculiarities);
            setPlaceDetention(petMedicalFormData.place_detention);
            setConditionsDetention(petMedicalFormData.conditions_detention);
        }
    }, [])

    // Handle Back Button Telegram
    const handleClickBack = () => {
        navigate('/home/pets/add');
    }


    return (
        <div className={style.pets_add_medical}>
            <BackButton onClick={handleClickBack} />
            {sterilization || !petMedicalForm
                ? (
                    <Form
                        labelCol={{ span: 4 }}
                        wrapperCol={{ span: 10 }}
                        layout="horizontal"
                        style={{ maxWidth: 300, margin: '0 auto' }}
                    >
                        <Form.Item style={{minWidth: '140px'}} className={style.sterilization}>
                            <Select
                                value={sterilization}
                                placeholder='Стерелизация'
                                onSelect={(value) => handleAddSexChange(value)}
                            >
                                <Select.Option value="Да">Да</Select.Option>
                                <Select.Option value="Нет">Нет</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item className={style.place}>
                            <Input
                                value={placeDetention}
                                placeholder='Место содержания'
                                onChange={(e) => setPlaceDetention(e.target.value)}
                            />
                        </Form.Item>
                        <Form.Item className={style.conditions}>
                            <Input
                                value={conditionsDetention}
                                placeholder='Условия содержания'
                                onChange={(e) => setConditionsDetention(e.target.value)}
                            />
                        </Form.Item>
                        <Form.Item className={style.chip_number}>
                            <Input
                                value={chipNumber}
                                placeholder='Номер чипа'
                                onChange={(e) => setChipNumber(e.target.value)}
                            />
                        </Form.Item>
                        <Form.Item className={style.peculiarities}>
                            <Input
                                value={peculiarities}
                                placeholder='Особенности'
                                onChange={(e) => setPeculiarities(e.target.value)}
                            />
                        </Form.Item>
                        <div onClick={handleSaveMedicalForm} className={style.button_medical_form}>
                            <ButtonMain text='Сохранить' />
                        </div>
                    </Form>
                )
                : (
                    <Spinner />
                )}
            
        </div>
    )
}

export default PetsAddMedical;