import style from './Documents.module.scss';

// React
import { useEffect } from 'react';

// Router
import { useNavigate } from 'react-router-dom';

// Telegram
import { BackButton, useWebApp, WebAppUser } from '@vkruglikov/react-telegram-web-app';

// Ant Design
import { FileFilled } from '@ant-design/icons';

// Redux
import { useSelector } from 'react-redux';
import { Status } from '../../types/types';
import { useAppDispatch } from '../../redux/store';
import { selectDocuments } from '../../redux/documents/selectors';
import { requestGetMyDocuments } from '../../redux/documents/asyncActions';

// Components
import Spinner from '../../components/Spinner/Spinner';
import EmptyPage from '../../components/EmptyPage/EmptyPage';


const Documents = () => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/home/profile');
    };

    // Telegram
    const WebApp = useWebApp();
    const user: WebAppUser = WebApp.initDataUnsafe.user;

    // Redux
    const dispatch = useAppDispatch();
    const { status, documents } = useSelector(selectDocuments);

    // Get Documents
    useEffect(() => {
        if (!documents.length && status === Status.CALM) {
            dispatch(requestGetMyDocuments())
        }
    }, [documents, status, dispatch])

    // Open File in Telegram Browser
    const handleOpenLink = (e: any, href: string) => {
        e.preventDefault();
        WebApp.openLink(href);
    }

    return (
        <div className={style.documents}>
            <BackButton onClick={handleClick} />
            {status === Status.LOADING 
                ? <Spinner />
                : (
                    <>
                        {documents.length > 0
                            ? (
                                <div className={style.my_documents}>
                                    {documents.map((document: any) => (
                                        <div className={style.document} key={document.id}>
                                            <FileFilled />
                                            <p onClick={(e) => handleOpenLink(e, document.link)}>{document.value}</p>
                                        </div>
                                    ))}
                                </div>
                            )
                            : <EmptyPage title='Мои документы' text='Здесь будут храниться документы, использованные в чатах' />
                        }
                    </>
                )
            }
        </div>
    )
}

export default Documents;