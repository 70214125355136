import style from './MenuLink.module.scss';

// Routers
import { Link } from 'react-router-dom';

// Ant Design
import { ArrowRightOutlined } from '@ant-design/icons';


type Props = {
    icon: any,
    href: string,
    text: string,
}

const MenuLink = ({ icon, href, text }: Props) => {
    return (
        <Link to={href} className={style.menu_link}>
            <div>
                {icon}
            </div>
            <div></div>
            <div>
                <p>{text}</p>
            </div>
            <div>
                <ArrowRightOutlined />
            </div>
        </Link>
    )
}

export default MenuLink;