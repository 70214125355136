import style from './Buttons.module.scss';

type PropsMainBtn = {
    text: string;
    disabled?: boolean;
}

export const ButtonMain = ({ text, disabled = false }: PropsMainBtn) => {
    return (
        <button disabled={disabled} className={style.main_btn}>{text}</button>
    )
}