import style from './Service.module.scss';

// Redux
import { useAppDispatch } from '../../../redux/store';

// Ant Design
import { RightCircleFilled } from '@ant-design/icons';
import { setPackageByAlias } from '../../../redux/packages/slice';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectPackages } from '../../../redux/packages/selectors';
import { useNavigate } from 'react-router-dom';


type Props = {
    icon: string,
    text: string,
    sub_text: string,
    alias?: string,
    price?: number,
    style_text?: any,
}

export const Service = ({ icon, text, price, sub_text, alias, style_text }: Props) => {
    const navigate = useNavigate();
    
    // Redux
    const dispatch = useAppDispatch();
    const { activePackage } = useSelector(selectPackages);
    
    const handleClick = (e: any) => {
        e.preventDefault();

        if (alias) {
            dispatch(setPackageByAlias({alias: alias}))
        }
    }

    useEffect(() => {
        if (activePackage) {
            navigate(`/home/service/${alias}`);
        }
    }, [activePackage, alias, navigate])

    return (
        <div className={style.home_page__service} onClick={alias ? handleClick : undefined}>
            <div>
                <img src={icon} alt="icon" />
            </div>
            <div className={style.line}></div>
            <div>
                <div className={style.description}>
                    <p style={style_text}>{text}</p>
                    <p>{sub_text}</p>
                </div>
                {price && (
                    <div className={style.price}>
                        <p>{price} рублей</p>
                    </div>
                )}
            </div>
            <div>
                <RightCircleFilled />
            </div>
        </div>
    )
}