import style from './About.module.scss';

// Router
import { useNavigate } from 'react-router-dom';

// Ant Design
import { RightCircleFilled } from '@ant-design/icons';

// Telegram
import { BackButton, useWebApp } from '@vkruglikov/react-telegram-web-app';

const About = () => {
    const navigate = useNavigate();

    // Telegram
    const WebApp = useWebApp();

    const handleClick = () => {
        navigate('/home/more');
    };

    const handleOpenLink = (e: any, href: string) => {
        e.preventDefault();
        WebApp.openLink(href);
    }

    return (
        <div className={style.about}>
            <BackButton onClick={handleClick} />
            <p>VetPet - это приложение ветеринарной помощи онлайн. Наш ветеринар будет наблюдать за здоровьем твоего пушистого друга в течение того времени, которое ты выбрал!</p>
            <div className={style.about__links}>
                <a onClick={(e) => handleOpenLink(e, 'https://vet-pet.online/docs/%D0%9E%D0%A4%D0%95%D0%A0%D0%A2%D0%90_%D0%9F%D0%A0%D0%90%D0%92%D0%98%D0%9B%D0%90_%D0%90%D0%91%D0%9E%D0%9D%D0%95%D0%9D%D0%A2%D0%A1%D0%9A%D0%9E%D0%93%D0%9E_%D0%9E%D0%91%D0%A1%D0%9B%D0%A3%D0%96%D0%98%D0%92%D0%90%D0%9D%D0%98%D0%AF_VETPET_%D0%A1%D0%90%D0%99%D0%A2_%D0%98_%D0%9C%D0%91_10_06.html')} className={style.link}>
                    <p>Пользовательское соглашение</p>
                    <RightCircleFilled />
                </a>
                <a onClick={(e) => handleOpenLink(e, 'https://vet-pet.online/docs/politika_konfidenczialnosti_vetpet-new.html')} className={style.link}>
                    <p>Политика конфиденциальности</p>
                    <RightCircleFilled />
                </a>
            </div>
            <div className={style.about__app}>
                <p>Версия 0.0.1</p>
                <a onClick={(e) => handleOpenLink(e, 'https://millenial.group/')}>Сайт Разработчика</a>
            </div>
        </div>
    )
}

export default About;