import style from './MessageFile.module.scss';

// Telegram
import { useWebApp } from '@vkruglikov/react-telegram-web-app';

// Redux
import { Message } from '../../../redux/chats/types';


type Props = {
    message: Message;
}

export const MessageFileSender = ({ message }: Props) => {
    // Telegram
    const WebApp = useWebApp();

    const onDownload = (src: string) => {
        WebApp.openLink(src);
    };

    return (
        <div className={style.send_message_file}>
            <p>{message.value}</p>
            <button onClick={() => onDownload(message.link)}>Скачать Документ</button>
        </div>
    )
}


export const MessageFileReceiver = ({ message }: Props) => {
    // Telegram
    const WebApp = useWebApp();

    const onDownload = (src: string) => {
        WebApp.openLink(src);
    };
    return (
        <div className={style.receiver_message_file}>
            <p>{message.value}</p>
            <button onClick={() => onDownload(message.link)}>Скачать Документ</button>
        </div>
    )
}