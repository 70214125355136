import style from './Avatar.module.scss';

// Ant Design
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';


type Props = {
    href?: string;
}

const AvatarComponent = ({ href }: Props) => {
    return (
        <div className={style.avatar_block}>
            <div></div>
            {href
                ? (
                    <Link to={href}>
                        <Avatar
                            icon={<UserOutlined />}
                            size={60}
                            style={
                                {
                                    width: '60px',
                                    height: '60px',
                                    fontSize: '30px',
                                    background: '#0D3C65',
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                }
                            }
                        />
                    </Link>
                )
                : (
                    <Avatar
                        icon={<UserOutlined />}
                        size={60}
                        style={
                            {
                                width: '60px',
                                height: '60px',
                                fontSize: '30px',
                                background: '#0D3C65',
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                            }
                        }
                    />
                )}
        </div>
    )
}

export default AvatarComponent;