import $api from '../http/index';

import { PromoCodeFormData } from '../redux/promocode/types';


export default class PromoCodeService {
    static async activatePromoCode(formData: PromoCodeFormData) {
        const res = await $api.get(`client/v1/promo/setup?code=${formData.promocode}&alias=${formData.service}`)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            return err.response.data;
        })
        return res;
    }
}