import { createSlice } from '@reduxjs/toolkit';

import { Status } from '../../types/types';
import { PaymentsSliceState } from './types';
import { requestCreatePaymentUrl } from './asyncActions';


const initialState: PaymentsSliceState = {
    message: '',
    status: Status.CALM as Status,
    payment_url: null,
}

const paymentSlice = createSlice({
    name: 'payments',
    initialState,
    reducers: {
        setPaymentUrlIsNull(state) {
            state.payment_url = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(requestCreatePaymentUrl.pending, (state) => {
            state.status = Status.LOADING;
        })
        builder.addCase(requestCreatePaymentUrl.fulfilled, (state, action) => {
            state.status = Status.SUCCESS;
            if (action.payload) {
                state.payment_url = action.payload.url;
            }
        })
        builder.addCase(requestCreatePaymentUrl.rejected, (state) => {
            state.status = Status.ERROR;
        })
    }
})

export const { setPaymentUrlIsNull } = paymentSlice.actions;

export default paymentSlice.reducer;