import style from './More.module.scss';

import { Link } from 'react-router-dom';

// Images
import iconInvite from '../../assets/img/more/1.png';

// Components
import { Service } from '../../components/Home/Service/Service';


const More = () => {
    return (
        <div className={style.more}>
            <Link className={style.invite_friend} to={'/home/invite-friend'}>
                <Service
                    icon={iconInvite}
                    text='Приглашайте друзей в приложение!'
                    sub_text=''
                    style_text={{fontSize: '16px', fontWeight: '600'}}
                />
            </Link>
            <Link className={style.link_menu} to={'/home/support'}>
                <p>Поддержка</p>
            </Link>
            <Link className={style.link_menu} to={'/home/about'}>
                <p>О приложении</p>
            </Link>
        </div>
    )
}

export default More;