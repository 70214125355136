import { createAsyncThunk } from "@reduxjs/toolkit";

import InviteService from "../../services/InviteService";

import { getInviteReferralFormData } from "./types";


export const requestGetInviteCode = createAsyncThunk('telegram-web-app/v1/get_invite_code', async () => {
    const response = await InviteService.getInvite();
    return response;
})


export const requestGetInviteReferral = createAsyncThunk('/getting_invite_code', async (formData: getInviteReferralFormData) => {
    const response = await InviteService.getInviteReferral(formData);
    return response;
})
