import style from './ServicesPay.module.scss';

// React
import { useEffect, useState } from 'react';

// Router
import { useNavigate, useParams } from 'react-router-dom';

// Ant Design
import { message as antdMessage } from 'antd';

// Telegram
import { BackButton, MainButton, useWebApp, WebAppUser } from '@vkruglikov/react-telegram-web-app';

// Redux
import { useSelector } from 'react-redux';
import { Status } from '../../types/types';
import { useAppDispatch } from '../../redux/store';
import { setPackageByAlias } from '../../redux/packages/slice';
import { selectPackages } from '../../redux/packages/selectors';
import { PromoCodeFormData } from '../../redux/promocode/types';
import { selectPromoCode } from '../../redux/promocode/selectors';
import { requestActivatePromoCode } from '../../redux/promocode/asyncActions';

// Components
import Spinner from '../../components/Spinner/Spinner';
import { selectPayments } from '../../redux/payments/selectors';
import { setPromocodeIsNull } from '../../redux/promocode/slice';
import { setPaymentUrlIsNull } from '../../redux/payments/slice';
import { CreateUrlPaymentFormData } from '../../redux/payments/types';
import { requestCreatePaymentUrl } from '../../redux/payments/asyncActions';
import { requestMyActivePackages } from '../../redux/my-packages/asyncActions';


const ServicesPay = () => {
    // Router
    let { alias } = useParams();
    const navigate = useNavigate();
    const [promocode, setPromocode] = useState<string>('');

    // Redux
    const dispatch = useAppDispatch();
    const { activePackage } = useSelector(selectPackages);

    useEffect(() => {
        if (alias) {
            dispatch(setPackageByAlias({ alias: alias }))
        }
    }, [dispatch, alias])

    // Show Alert
    const [messageApi, contextHolder] = antdMessage.useMessage();

    // Promocode
    const { status, message, promo_package } = useSelector(selectPromoCode);

    // Activate PromoCode
    const handlerActivatePromocode = (e: any) => {
        e.preventDefault();
        e.target.disabled = true;

        // Activate PromoCode
        if (promo_package) {
            messageApi.info('Вы уже активировали промокод', 2);
            e.target.disabled = false;
            return;
        }

        if (promocode.length === 0) {
            messageApi.error('Пожалуйста введите промокод', 2);
            e.target.disabled = false;
            return;
        }
 
        if (alias) {
            const formData: PromoCodeFormData = {
                promocode: promocode,
                service: alias,
            }
            dispatch(requestActivatePromoCode(formData));
        }
        e.target.disabled = false;
    }

    useEffect(() => {
        if (status !== Status.CALM && message.length > 0) {
            if (status === Status.SUCCESS) {
                messageApi.success(message, 2);
                return;
            }
            if (status === Status.ERROR) {
                messageApi.error(message, 2);
                return;
            }
        }
    }, [status, message, messageApi])

    // Payment Handler
    const WebApp = useWebApp();
    const { payment_url } = useSelector(selectPayments);

    const payment_handler = () => {
        if (activePackage) {
            const user: WebAppUser = WebApp.initDataUnsafe.user;
            
            const formData: CreateUrlPaymentFormData = {
                alias: activePackage.alias,
                price: promo_package ? promo_package.price : activePackage.price,
                telegram_id: user.id,
                description: activePackage.description,
                verbose_name: activePackage.verbose_name
            }
            dispatch(requestCreatePaymentUrl(formData));

            WebApp.onEvent('invoiceClosed', (object: any) => {
                if (object.status == 'failed') {
                    messageApi.error('Произошла ошибка при оплате', 2);
                }
                else if (object.status == 'cancelled') {
                    messageApi.info('Вы отменили оплату', 2);
                }
            });
        }
    }

    useEffect(() => {
        if (payment_url) {
            WebApp.openInvoice(payment_url);
        }
        return () => {
            dispatch(setPromocodeIsNull());
            dispatch(setPaymentUrlIsNull());
            dispatch(requestMyActivePackages());
        }
    }, [payment_url, WebApp, dispatch])


    const handleClickBackTelegram = () => {
        navigate(`/home/service/${alias}`);
    };

    return (
        <div className={style.services_pay}>
            <BackButton onClick={handleClickBackTelegram} />
            {contextHolder}
            {!activePackage
                ? (
                    <Spinner />
                )
                : (
                    <>
                        <div className={style.services_pay__price}>
                            <h2>К оплате: {promo_package ? promo_package.price : activePackage?.price}</h2>
                        </div>
                        <div className={style.services_pay__promocode}>
                            <p>Есть промокод?</p>
                            <form>
                                <input
                                    type="text"
                                    value={promocode}
                                    onChange={(e) => setPromocode(e.target.value)} />
                                <button onClick={handlerActivatePromocode}>Применить</button>
                            </form>
                        </div>
                        <MainButton onClick={payment_handler} color='#8DE719' text='Оплатить' />
                    </>
                )
            }
        </div>
    )
}

export default ServicesPay;