import style from './PetProfile.module.scss';

// Images
import imgAnimal from '../../assets/img/pets/cil-animal.svg';

// React
import { useEffect, useState } from 'react';

// Router
import { Link, useNavigate, useParams } from 'react-router-dom';

// Redux
import { useSelector } from 'react-redux';
import { Status } from '../../types/types';
import { Pet } from '../../redux/pets/types';
import { useAppDispatch } from '../../redux/store';
import { selectPets } from '../../redux/pets/selectors';
import { requestGetMyPets } from '../../redux/pets/asyncActions';

// Ant Design
import { ArrowRightOutlined, CalendarOutlined } from '@ant-design/icons';

// Telegram
import { BackButton } from '@vkruglikov/react-telegram-web-app';

// Components
import Spinner from '../../components/Spinner/Spinner';



const PetProfile = () => {
    const navigate = useNavigate();

    let { petId } = useParams();
    const [pet, setPet] = useState<Pet | undefined>();

    // Redux
    const dispatch = useAppDispatch();
    const { status, pets } = useSelector(selectPets);

    useEffect(() => {
        if (status === Status.CALM && pets.length === 0) {
            dispatch(requestGetMyPets())
        }
    }, [dispatch, pets])

    useEffect(() => {
        if (petId && pets.length > 0 && parseInt(petId)) {
            const idx = pets.findIndex((el) => el.id === Number(petId));
            if (idx !== -1) {
                setPet(pets[idx]);
            }
        }
    }, [pets, petId])

    // Handle Back Button Telegram
    const handleClick = () => {
        navigate('/home/pets');
    };

    const handleClickMedicalCard = (e: any) => {
        e.preventDefault();

        // Add To LocalStorage Pet Data
        localStorage.setItem('pet_form', JSON.stringify({
            name: pet?.name,
            kind: pet?.kind,
            breed: pet?.breed,
            kind_name: pet?.kind_name,
            breed_name: pet?.breed_name,
            birth_date: pet?.birth_date,
            weight: pet?.weight,
            sex: pet?.sex,
        }))

        // Add To LocalStorage Medical Data
        localStorage.setItem('pet_medical_form', JSON.stringify({
            'chip_number': pet?.nfc_code,
            'sterilization': pet?.sterilization ? 'Да' : 'Нет',
            'peculiarities': pet?.peculiar,
            'place_detention': pet?.detention_place,
            'conditions_detention': pet?.detention_conditions,
        }))

        navigate(`/home/pets/medical_card/profile/${pet?.id}`);
    }

    return (
        <div className={style.pet_profile}>
            <BackButton onClick={handleClick} />
            {petId && pet
                ? (
                    <>
                        <div className={style.profile__info_block}>

                            <>
                                <div className={style.pet_profile__info}>
                                    <div className={style.pet_img}>
                                        <img src={imgAnimal} alt={`animal-${petId}`} />
                                    </div>
                                    <div className={style.pet_name}>
                                        <p className={style.pet_name__name}>{pet.name}</p>
                                        <p className={style.pet_name__birth_date}>{pet.birth_date}</p>

                                        <Link to={''} onClick={handleClickMedicalCard}>
                                            <p>Медкарта</p>
                                            <ArrowRightOutlined />
                                        </Link>
                                    </div>
                                </div>
                                <Link to={'/home/chats'} className={style.pet_profile__consultation}>
                                    <p>Проконсультируйтесь с ветеринаром</p>
                                    <ArrowRightOutlined />
                                </Link>
                                <Link to={'/home/chats'} className={style.pet_profile__consultation_date}>
                                    <div>
                                        <CalendarOutlined />
                                    </div>
                                    <div></div>
                                    <div>
                                        <p>Ближайшая консультация</p>
                                        <p>Нет</p>
                                    </div>
                                    <div>
                                        <ArrowRightOutlined />
                                    </div>
                                </Link>
                            </>
                        </div>
                        <div className={style.pet_profile__history_consultations}>
                            <h2>История консультаций</h2>
                        </div>
                    </>
                )
                : <Spinner />}
        </div>
    )
}

export default PetProfile;