import style from './Home.module.scss';

// Images
import pawAnimal from '../../assets/img/home/mdi--paw.svg';

// Ant Design
import { CalendarOutlined } from '@ant-design/icons';

// Telegram
import { useWebApp, WebAppUser } from '@vkruglikov/react-telegram-web-app';

// Components
import MenuLink from '../../components/Home/MenuLink/MenuLink';
import Packages from '../../components/Home/Packages/Packages';
import AvatarComponent from '../../components/Home/Avatar/Avatar';


const Home = () => {
    const WebApp = useWebApp();

    const user: WebAppUser = WebApp.initDataUnsafe.user;

    return (
        <div className={style.home_page}>
           <div className={style.home_page__action_block}>
                <div className={style.home__user_block}>
                    <AvatarComponent href='/home/profile' />
                    <p className={style.name}>{user?.username}</p>
                </div>
                <div className={style.home__menu_block}>
                    <MenuLink href={'/home/chats'} icon={<CalendarOutlined />} text='Выбрать консультацию' />
                    <MenuLink href={'/home/pets'} icon={<img src={pawAnimal} alt="paw" />} text='Добавить питомца' />
                </div>
            </div>
            <div className={style.home_page__services_block}>
                <h1>Услуги</h1>
                <div className={style.home_page__services}>
                    <Packages />
                </div>
            </div>
        </div>
    )
}

export default Home;