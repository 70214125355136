import style from './TermsOfAction.module.scss';

// Router
import { useNavigate } from 'react-router-dom';

// Telegram
import { BackButton } from '@vkruglikov/react-telegram-web-app';


const TermsOfAction = () => {
    // Router
    const navigate = useNavigate();

    // Handle Back Button
    const handleClickBack = () => {
        navigate('/home/invite-friend');
    }
    return (
        <div className={style.terms_action}>
            <BackButton onClick={handleClickBack} />
            <ol>
                <li>
                    Приглашать могут только те пользователи, которые ранее купили одну из услуг VetPet и активировали ее.
                </li>
                <li>
                    Вознаграждение должно активироваться только за нового пользователя в сервисе VetPet, то есть, приглашаемый
                    друг не должен являться клиентом сервиса VetPet.
                </li>
                <li>
                    Приглашенный должен купить по ссылке приглашающего любую услугу
                </li>
                <li>
                    Друг может воспользоваться купленной услугой, но важно не делать возврат оплаты, иначе вознаграждение начислено не будет.
                </li>
            </ol>
        </div>
    )
}

export default TermsOfAction;